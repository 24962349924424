import React, { useEffect, useState } from 'react'
import "./Dashboard.scss"
import DownloadIcon from '@mui/icons-material/Download';
import { Link, useNavigate } from 'react-router-dom';
import { MODULES_API_MAP, httpService } from '@Src/services/httpService';
import { GLOBAL_API_ROUTES } from '@Src/services/globalApiRoutes';
import { IAPIResponse, IToken } from '@Src/types/api-response-interface';
import { LOCAL_STORAGE_DATA_KEYS } from '@Constants/localstorageDataModel';
import { getDataFromLocalStorage } from '@Src/utils/globalUtilities';
import { jwtDecode } from 'jwt-decode';
import { IFinalValues } from '@Src/types/dashboard-interface';
import jsPDF from 'jspdf';
import LifeCareerPdf from '@Pages/life-career-pdf/LifeCareerPdf';
import Test1Pdf from '@Pages/life-career-pdf/components/test-1-pdf/Test1Pdf';
import Test2Pdf from '@Pages/life-career-pdf/components/test-2-pdf/Test2Pdf';
import Test3Pdf from '@Pages/life-career-pdf/components/test-3-pdf/Test3Pdf';
import Loader from '@Components/loader/Loader';

const Dashboard = () => {

    const [finalValues, setFinalValues] = useState<IFinalValues>()

    const [progressValues, setProgressValues] = useState<{ t1: number, t2: number, t3: number }>()
    const [showAllPDfHtml, setShowAllPdfHTml] = useState(false)
    const [showT1PdfHtml, setShowT1PdfHTml] = useState(false)
    const [showT2PDfHtml, setShowT2PdfHTml] = useState(false)
    const [showT3PDfHtml, setShowT3PdfHTml] = useState(false)
    const [showLoader, setShowLoader] = useState(false)

    const [name, setName] = useState('')

    const getFinalValues = async () => {
        setShowLoader(true)
        try {
            let response: IAPIResponse = await httpService(MODULES_API_MAP.BASE_API_ROUTE, GLOBAL_API_ROUTES.FINAL_VALUES).GET()
            if (response.success) {
                setFinalValues(response.data)
            }
        } catch (err) {
            console.log(err)
        }
        setShowLoader(false)
    }
    const getProgress = async () => {
        try {
            let response: IAPIResponse = await httpService(MODULES_API_MAP.BASE_API_ROUTE, GLOBAL_API_ROUTES.PROGRESS).GET()
            if (response.success) {
                setProgressValues(response.data)
            }
        } catch (err) {
            console.log(err)
        }
    }
    const getRevisit = async (id: number) => {
        setShowLoader(true)
        try {
            let response: IAPIResponse = await httpService(MODULES_API_MAP.BASE_API_ROUTE, GLOBAL_API_ROUTES.REVISIT).POST({ test_id: id })
            if (response.success) {
                setProgressValues(response.data)
                navigate(`/test/${id}`)
            }
        } catch (err) {
            console.log(err)
        }
        setShowLoader(false)
    }

    const getButtonText = (value: any) => {
        if (value === 3) return 'Revisit';
        if (value === 1 || value === 2) return 'Continue';
        return 'Start Now';
    };

    const redirectConditionally = (progressID: any) => {
        if (progressID == 1) {
            if (progressValues?.t1 != 3) {
                navigate('/test/1')
            } else {
                getRevisit(1)
            }
        } else if (progressID == 2) {
            if (progressValues?.t2 != 3) {
                navigate('/test/2')
            } else {
                getRevisit(2)
            }
        }
        else if (progressID == 3) {
            if (progressValues?.t3 != 3) {
                navigate('/test/3')
            } else {
                getRevisit(3)
            }
        }

    }
    const token = getDataFromLocalStorage(LOCAL_STORAGE_DATA_KEYS.AUTH_KEY);

    const navigate = useNavigate()

    const generateAllPagesPDF = () => {
        let doc: any = new jsPDF("p", "px", "a4")
        const opt = {
            callback: function (jsPdf: any) {
                const blob = jsPdf.output('blob');
                const url = URL.createObjectURL(blob);
                window.open(url);
                // jsPdf.save("Test.pdf");
            },
            // margin: [10, 20, 20, 20], // Set appropriate margins
            autoPaging: 'text', // Crucial for handling text flow across pages
            html2canvas: {
                allowTaint: true,
                letterRendering: true,
                logging: false,
                scale: 0.4, // Adjust the scale to fit content
            },
        };
        doc.html(document.querySelector('.pdf-downloader'), opt)
    }

    const generateT1PDF = () => {
        let doc: any = new jsPDF("p", "px", "a4")
        const opt = {
            callback: function (jsPdf: any) {
                const blob = jsPdf.output('blob');
                const url = URL.createObjectURL(blob);
                window.open(url);
                // jsPdf.save("Test.pdf");
            },
            // margin: [10, 20, 20, 20], // Set appropriate margins
            autoPaging: 'text', // Crucial for handling text flow across pages
            html2canvas: {
                allowTaint: true,
                letterRendering: true,
                logging: false,
                scale: 0.4, // Adjust the scale to fit content
            },
        };
        doc.html(document.querySelector('.test-1'), opt)
    }

    const generateT2PDF = () => {
        let doc: any = new jsPDF("p", "px", "a4")
        const opt = {
            callback: function (jsPdf: any) {
                const blob = jsPdf.output('blob');
                const url = URL.createObjectURL(blob);
                window.open(url);
                // jsPdf.save("Test.pdf");
            },
            // margin: [10, 20, 20, 20], // Set appropriate margins
            autoPaging: 'text', // Crucial for handling text flow across pages
            html2canvas: {
                allowTaint: true,
                letterRendering: true,
                logging: false,
                scale: 0.4, // Adjust the scale to fit content
            },
        };
        doc.html(document.querySelector('.test-2'), opt)
    }

    const generateT3PDF = () => {
        let doc: any = new jsPDF("p", "px", "a4")
        const opt = {
            callback: function (jsPdf: any) {
                const blob = jsPdf.output('blob');
                const url = URL.createObjectURL(blob);
                window.open(url);
                // jsPdf.save("Test.pdf");
            },
            // margin: [10, 20, 20, 20], // Set appropriate margins
            autoPaging: 'text', // Crucial for handling text flow across pages
            html2canvas: {
                allowTaint: true,
                letterRendering: true,
                logging: false,
                scale: 0.4, // Adjust the scale to fit content
            },
        };
        doc.html(document.querySelector('.test-3'), opt)
    }

    const handleTest1pdf = () => {
        setShowT1PdfHTml(true)
        setTimeout(() => {
            generateT1PDF()
            setShowT2PdfHTml(false)
        }, 500);

    }
    const handleTest2pdf = () => {
        setShowT2PdfHTml(true)
        setTimeout(() => {
            generateT2PDF()
            setShowT2PdfHTml(false)
        }, 500);

    }
    const handleTest3pdf = () => {
        setShowT3PdfHTml(true)
        setTimeout(() => {
            generateT3PDF()
            setShowT3PdfHTml(false)
        }, 500);
    }
    const handleAllPdf = () => {
        setShowAllPdfHTml(true)
        setTimeout(() => {
            generateAllPagesPDF();
            setShowAllPdfHTml(false)
        }, 500);
    }




    useEffect(() => {
        if (token) {
            getFinalValues()
            getProgress()
        }
    }, [])


    useEffect(() => {
        if (token) {
            const decodeToken: IToken = jwtDecode(token);
            const currentTime = Math.floor(Date.now() / 1000);
            if (decodeToken.exp < currentTime) {
                navigate('/')
                window.localStorage.clear()
            } else {
                const [localPart] = decodeToken.email.split('@');
                const cleanedLocalPart = localPart.replace(/[^a-zA-Z.]/g, '');
                const nameParts = cleanedLocalPart.split('.');
                const name = nameParts
                    .map(part => part.charAt(0).toUpperCase() + part.slice(1))
                    .join(' ');

                setName(name)
            }
        }
    }, [token])




    return (
        <div className=' md:tw-px-[7rem] md:tw-py-4 tw-p-4 '>
            {showLoader && <Loader />}
            <div className="tw-ml-2.5 tw-mr-35">
                <h4 className="tw-text-primary tw-font-bold tw-text-lg">
                    Life/Career Values Instructions
                </h4>

                <h4 className="tw-text-primary tw-font-bold tw-text-lg">
                    PART 1: YOUR PERSONAL OR "WHOLE LIFE" VALUES
                </h4>

                <h5 className="tw-text-[#37B048]  tw-my-5 tw-font-semibold tw-text-lg">
                    Step 1 - Exploring Values
                </h5>
                <p className='tw-mt-4'>
                    To begin, think about your life in total. This includes your career, family, social life, personal time, community, the world. Also think about relationships, happiness, spirituality. Even think about your views of money or success. In other words, think about everything in your whole life
                </p>

                <p className='tw-mt-4'>
                    In Step 1, you will see a list of six values on the page. Review each value, asking yourself if it is important to you or not.{' '}
                    <span className="tw-text-[#37B048] tw-font-bold">
                        You define these values. Don't worry if you don't have an exact definition.
                    </span>{' '}
                    Those that are unimportant may include values that you believe could be important to someone but not so much to you. Click the arrow to move those that are  <strong>NOT</strong> important to you to the box on the right, leaving values in the lefthand column that are important to you.
                </p>

                <p className='tw-mt-4'>
                    You may select as many values as you want. You can move a value to the other box and move it back if you change your mind. You can go back a page to make changes if you want to do so.{' '}
                    <span className="tw-text-[#37B048] tw-font-bold">
                        Take your time but don't debate this in your mind too much. If you are not sure how important a given value is to you, leave it as "Important".
                    </span>{' '}
                    Click on Next when you are ready to move to the next set of values.
                </p>

                <h5 className="tw-text-[#37B048] tw-my-5 tw-font-semibold tw-text-lg">
                    Step 2 - Top Ten Values
                </h5>
                <p className='tw-mt-4'>
                    Once you have gone through each page of values, you will be presented with a list of your Important values. From the list of Important values, indicate your Top Ten by dragging values to the box on the right. These are the values that you believe are the most significant or important to you -- the values that you would fight for if you had to do so.{' '}
                    <span className="tw-text-[#37B048] tw-font-bold">
                        Don't worry about the order you place them in right now, just select the top ten.
                    </span>{' '}
                    You may only pick ten, so if there is another value you really want to add to your top ten, you must remove one to add different one.
                </p>

                <h5 className="tw-text-[#37B048]  tw-my-5 tw-font-semibold tw-text-lg">
                    Step 3 - Rank Your Top Ten
                </h5>
                <p className='tw-mt-4'>
                    Carefully review your Top Ten values in the box. Think about which one is most important to you and the next most important, and so on. You may reorder the values by selecting and dragging each value up or down.{' '}
                    <span className="tw-text-[#37B048] tw-font-bold">
                        Arrange the values such that your most important value is at the top, number 1, and so on,
                    </span>{' '}
                    thereby creating a rank ordered list of your Top Ten values.
                </p>

            </div>
            <p className='tw-text-3xl tw-font-semibold tw-mt-5'>{name} -- Your Important Values</p>
            {(progressValues?.t1 === 3 && progressValues.t2 === 3 && progressValues.t3 === 3) &&

                <div className='tw-mt-5'>
                    <p className='tw-text-lg  '>Now you may view your most important or Top Ten Values from your prior visit(s). You may click each one to download a pdf of that list:</p>
                    <div className='tw-grid md:tw-grid-cols-3 tw-gap-5 tw-grid-cols-1 tw-mt-4'>
                        <div className="card">
                            <div className="card-header">
                                Whole Life Values
                            </div>
                            {/* <div className="card-content">
                            Click the button below to download your results.
                        </div> */}
                            <button
                                onClick={() => handleTest1pdf()}
                                className="download-button"
                            // onClick={handleDownload}
                            // {<DownloadIcon />
                            >
                                Download Results
                            </button>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                Current/Most Recent Career Values
                            </div>
                            {/* <div className="card-content">
                            Click the button below to download your results.
                        </div> */}
                            <button
                                onClick={() => handleTest2pdf()}

                                className="download-button"
                            // onClick={handleDownload}
                            // {<DownloadIcon />
                            >
                                Download Results
                            </button>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                Ideal Career Values
                            </div>
                            {/* <div className="card-content">
                            Click the button below to download your results.
                        </div> */}
                            <button
                                className="download-button"
                                onClick={() => handleTest3pdf()}

                            // onClick={handleDownload}
                            // {<DownloadIcon />
                            >
                                Download Results
                            </button>
                        </div>
                    </div>
                </div>
            }
            {(progressValues?.t1 === 3 && progressValues.t2 === 3 && progressValues.t3 === 3) && <div className='tw-mt-5'>
                <p className='tw-text-lg tw-text-center'>If you have completed all three of the above sections, you may now view a Results page, which includes each of the above three lists AND instructions on how to explore your results, section by section, comparing your values in each list. You may also download your Results page.  </p>
                <div className='tw-flex tw-justify-center tw-items-center tw-mt-4'>
                    <div className="card">
                        <div className="card-header">
                            Exploring All Three Lists of Your Top Ten Values
                        </div>
                        {/* <div className="card-content">
                            Click the button below to download your results.
                        </div> */}
                        <button
                            className="download-button"
                            onClick={() => handleAllPdf()}

                        // onClick={handleDownload}
                        // {<DownloadIcon />
                        >
                            Download Results
                        </button>
                    </div>
                </div>
            </div>}
            <div className='tw-mt-5'>
                {/* <p className='tw-text-lg'>If you haven't yet completed all three sections, simply click the "Take ..." link below.</p> */}
                {/* <p className='tw-mt-4'>You may make changes to any of these three sections by retaking that section of the assessment by clicking on the "Revisit..." link below. You may only Revisit each section once.</p> */}
                <div className='tw-grid md:tw-grid-cols-3 tw-grid-cols-1 tw-gap-3 tw-mt-5'>
                    <div className="section">
                        <div className="section-header">
                            {' Whole Life Values'}
                        </div>
                        <button onClick={() => redirectConditionally(1)} className="revisit-button">
                            {getButtonText(progressValues?.t1)} {'Whole Life Values'}
                        </button>                   </div>

                    <div className="section">
                        <div className="section-header">
                            {'Current/Most Recent Career Important Values'}
                        </div>
                        <button onClick={() => redirectConditionally(2)} className="revisit-button">
                            {getButtonText(progressValues?.t2)} {'Career Important Values'}
                        </button>
                    </div>

                    <div className="section">
                        <div className="section-header">
                            {'Revisit Ideal Career Values'}
                        </div>
                        <button onClick={() => redirectConditionally(3)} className="revisit-button">
                            {getButtonText(progressValues?.t3)} {'Ideal Career Values'}
                        </button>                   </div>
                </div>
                <p className='tw-text-xl tw-mt-9'>Exploring all three lists is the most important part of completing this assessment. Please download it and take the time to complete the exercises. Then you may wish to move on to the<b className='tw-text-blue-500 tw-underline tw-cursor-pointer'><Link to={'/nextSteps'}> Next Steps.</Link></b></p>
                {showAllPDfHtml && <LifeCareerPdf name={name} finalValuesData={finalValues} />}
                {showT1PdfHtml && <Test1Pdf name={name} finalValues={finalValues} />}
                {showT2PDfHtml && <Test2Pdf name={name} finalValues={finalValues} />}
                {showT3PDfHtml && <Test3Pdf name={name} finalValuesData={finalValues} />}
            </div>
        </div>
    )
}

export default Dashboard